











































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Environment, EnvironmentUser, User, UserProfile } from '@/models'
import _cloneDeep from 'lodash/cloneDeep'
import _pick from 'lodash/pick'
import _mapKeys from 'lodash/mapKeys'
import Form from '@/components/form/Form.vue'
import Loading from '@/components/Loading.vue'
import gql from 'graphql-tag'
import { ApolloError } from 'apollo-client'
import PaginatedList from '@/components/PaginatedList.vue'
import ComponentCreateDialog from '@/components/ComponentCreateDialog.vue'
import ComponentEditorDialog from '@/components/ComponentEditorDialog.vue'
import { environmentUsers } from '@/components/componentTypes/environmentUsers'

@Component({
  components: {
    Form,
    Loading,
    PaginatedList,
    ComponentEditorDialog,
    ComponentCreateDialog
  }
})
export default class EnvironmentManageUsers extends Vue {
  @Prop({ type: Object, required: true }) environment !: Environment
  @Prop({ type: Object, required: true }) environmentUser !: EnvironmentUser
  @Prop({ type: String, default: '' }) userId !: string

  componentType = environmentUsers

  saving = false

  createModalOpen = false

  get componentEditorOpen () {
    return !!this.userId
  }

  set componentEditorOpen (v : boolean) {
    if (!v) {
      this.$router.push('/manage/users').catch(console.error)
      const results = this.$refs.results as any
      results.update()
    }
  }

  get user () : User {
    return this.$store.state.auth.user
  }

  get isAdmin () {
    return this.user.roles.indexOf('admin') >= 0 || this.user.roles.indexOf('superAdmin') >= 0
  }

  /** Open or close the creation modal */
  toggleCreateModal (value : any) {
    this.createModalOpen = value != null && typeof value === 'boolean' ? value : !this.createModalOpen
  }

  handleCreate (component : any) {
    if (component && component._id) {
      return this.$router.push(`/manage/users/${component._id}`)
    }
  }

  transformResult (envUser : EnvironmentUser) {
    return {
      ...this.componentType.transformResult(envUser),
      href: `/manage/users/${envUser._id}`
    }
  }
}
